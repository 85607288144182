import React from "react";
import styled from "styled-components";
import useClock from "./useClock";
import { Circle } from "./ui";
import Hands from './Hands'
// import useClock from "./useClock";
import sun from '../../../../src/assets/images/sun.svg';
import moon from '../../../../src/assets/images/moon.svg';


// const Mark = styled.div`
//   position: absolute;
//   width: 1px;
//   height: 30px;
//   background: black;
//   border-radius: 100px;
//   top: 10px;
//   left: 50%;
//   transform: rotate(${props => props.rotation || "0deg"})
//     translate3d(-50%, 0, 0);
//   transform-origin: 0px 130px;
// `;

// const marks = Array(12).fill();

const Clock = ({ className, size }) => {

  const time = useClock('oakland');

  return (
    <>
      <div className="timetitle oak">
        <span>
        Oakland, California
        </span>
        <span>
          Currently {time.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })} (PST)
        </span>
      </div>
      <Circle size={size} className={className}>
        
        {/* {marks.map((_, i) => (
        <Mark key={i} size={size} rotation={i * 30 + "deg"} />
      ))} */}
        <Hands time={time} borderWidth={0} />
        <div className="sunmoon" style={{
        transform: 'rotate(' + ((time.getHours() % 12) + time.getMinutes() / 60) * 30 + "deg" + ')'
      }}>
        {time.getHours() >= 20 || time.getHours() <= 8 ?
          <img className="moon" style={{
            transform: 'rotate(-' + ((time.getHours() % 12) + time.getMinutes() / 60) * 30 + "deg" + ')'
          }} src={moon} alt="" />
          :
          <img src={sun} alt=""  />
        }
      </div>
      </Circle>
    </>
  );
};

const StyledClock = styled(Clock)`
  position: relative;
  border: 2px solid #ffffff;
  background: transparent;
`;
// const StyledClock = ({ className, size }) => {
//   return (
//     <div style={{'position': 'relative', 'border': '2px solid #ffffff', 'background': 'transparent'}}></div>
//   );
// }

export default StyledClock;
