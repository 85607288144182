import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import anime from 'animejs';

import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';

// Styles
import "./homewhatwedo.scss";


function Homewhatwedo(props) {
  // useEffect(() => {
  //   var tl = anime.timeline({
  //     easing: 'easeOutExpo',
  //     duration: 450,
  //     direction: 'alternate', // Is not inherited
  //     loop: true
  //   });

  //   tl
  //     .add({
  //       targets: '#animation-15 .smallcircle',
  //       opacity: 1,
  //     }, 400)
  //     .add({
  //       targets: '#animation-15 .smallcircle',
  //       translateX: 0,
  //       translateY: 0,
  //     })
  //     .add({
  //       targets: '#animation-15 .firstcircle',
  //       opacity: 1,
  //     }, 800)
  //     .add({
  //       targets: '#animation-15 .firstcircle',
  //       translateX: 0,
  //       translateY: 0,
  //     }, 1200) // relative offset
  //     .add({
  //       targets: '#animation-15 .secondcircle',
  //       opacity: 1,
  //     }, 1600)
  //     .add({
  //       targets: '#animation-15 .secondcircle',
  //       translateX: 0,
  //       translateY: 0,
  //     }, 2000)
  //     .add({
  //       targets: '#animation-15 .thirdcircle',
  //       opacity: 1,
  //     }, 2400)
  //     .add({
  //       targets: '#animation-15 .thirdcircle',
  //       translateX: 0,
  //       translateY: 0,
  //     }, 2800);
  // })


  useEffect(() => {
    const path = document.querySelectorAll(".gpath");
    // used to set our custom property values
    const root = document.documentElement;

    let hueNoiseOffset = 0;
    let noiseStep = 0.005;

    const simplex = new SimplexNoise();



    const animate = () => {
      path.forEach(element => {
        element.setAttribute("d", spline(points, 1, true));
      });
      // path.

      // for every point...
      for (let i = 0; i < points.length; i++) {
        const point = points[i];

        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
        const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

        // update the point's current coordinates
        point.x = x;
        point.y = y;

        // progress the point's x, y values through "time"
        point.noiseOffsetX += noiseStep;
        point.noiseOffsetY += noiseStep;
      }

      const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
      const hue = map(hueNoise, -1, 1, 0, 360);

      // root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
      root.style.setProperty("--startColor", `hsl(274, 100%, 75%)`);
      root.style.setProperty("--stopColor", `hsl(57deg, 100%, 75%)`);
      // root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
      // document.body.style.background = `hsl(${hue + 60}, 75%, 5%)`;

      hueNoiseOffset += noiseStep / 6;

      requestAnimationFrame(animate);

    };

    const map = (n, start1, end1, start2, end2) => {
      return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    const noise = (x, y) => {
      return simplex.noise2D(x, y);
    }

    const createPoints = () => {
      const points = [];
      // how many points do we need
      const numPoints = 6;
      // used to equally space each point around the circle
      const angleStep = (Math.PI * 2) / numPoints;
      // the radius of the circle
      const rad = 75;

      for (let i = 1; i <= numPoints; i++) {
        // x & y coordinates of the current point
        const theta = i * angleStep;

        const x = 100 + Math.cos(theta) * rad;
        const y = 100 + Math.sin(theta) * rad;

        // store the point's position
        points.push({
          x: x,
          y: y,
          // we need to keep a reference to the point's original point for when we modulate the values later
          originX: x,
          originY: y,
          // more on this in a moment!
          noiseOffsetX: Math.random() * 1000,
          noiseOffsetY: Math.random() * 1000
        });
      }

      return points;
    }
    const points = createPoints();
    animate();
    // document.querySelector(".visible").addEventListener("click", () => {
    //   debugger;
    //   noiseStep = 0.005;
    //   document.querySelectorAll('.blobsvg').forEach(element => {
    //     debugger;
    //     element.classList.add('bigger');
    //     element.classList.remove('fluidanim');
    //     element.classList.remove('structure');
    //   });
    // });
    document.querySelector(".visiblee").addEventListener("click", () => {
      noiseStep = 0.005;
      document.querySelectorAll('.blobsvg').forEach(element => {
        element.classList.add('bigger');
        element.classList.remove('fluidanim');
        element.classList.remove('structure');
        element.classList.remove('bounce')
      });
    });

    document.querySelector(".invisible").addEventListener("click", () => {
      noiseStep = 0.005;
      document.querySelectorAll('.blobsvg').forEach(element => {
        element.classList.remove('bigger');
        element.classList.remove('fluidanim');
        element.classList.remove('structure');
        element.classList.remove('bounce')
      });
    });
    document.querySelector(".fluid").addEventListener("click", () => {
      noiseStep = 0.005;
      document.querySelectorAll('.blobsvg').forEach(element => {
        element.classList.remove('bigger');
        element.classList.add('fluidanim');
        element.classList.remove('structure');
        element.classList.remove('bounce')
      });
    });
    document.querySelector(".structured").addEventListener("click", () => {
      noiseStep = 0.001;
      document.querySelectorAll('.blobsvg').forEach(element => {
        element.classList.remove('bigger');
        element.classList.remove('fluidanim');
        element.classList.add('structure');
        element.classList.remove('bounce')
      });
    });
    document.querySelectorAll(".blobsvg")[3].addEventListener("mouseover", () => {
      noiseStep = 0.010;
    })
    document.querySelectorAll(".blobsvg")[3].addEventListener("mouseleave", () => {
      noiseStep = 0.005;
    })
    document.querySelectorAll(".blobsvg")[3].addEventListener("dblclick", () => {
      noiseStep = 0.005;
      document.querySelectorAll('.blobsvg').forEach(element => {
        element.classList.remove('bigger');
        element.classList.add('fluidanim');
        element.classList.remove('structure');
        element.classList.remove('bounce');
      });
    });
    var dd = document.getElementsByClassName('blobsvg');

    Array.from(dd).forEach(element => {
      element.addEventListener('click', (elementt) => {
        if (elementt.currentTarget.classList.contains('fluidanim')) {
          elementt.currentTarget.classList.add('bounce')
        }
      });

    });
    document.querySelectorAll(".blobsvg")[3].addEventListener("mouseover", () => {
      document.querySelectorAll('.blobsvg').forEach(element => {
        if (!element.classList.contains('fluidanim')) {
          element.classList.add('bounce')
        }
      })
    })
    document.querySelectorAll(".blobsvg")[3].addEventListener("mouseleave", () => {
      document.querySelectorAll('.blobsvg').forEach(element => {
        if (!element.classList.contains('fluidanim')) {
          element.classList.remove('bounce')
        }
      })
    })

  })



  return (
    <div className="homewhatwedo">
      <div className="titlesec">
        <div className="maintitle">
          <NavLink aria-current="page" to="/whatwedo">What We Do</NavLink>
          <p>We build technology <span className="visiblee">visible</span> & <span className="invisible">invisible</span>,<br />
            <span className="fluid">fluid</span> & <span className="structured">structured</span>, to ripen your idea at any point of it’s journey.</p>
        </div>
        <div className="wedoanimation">
          <svg viewBox="0 0 200 200" className="blobsvg">
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
                <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
              </linearGradient>
            </defs>
            <path className="gpath" d="" fill="url('#gradient')"></path>
          </svg>
          <svg viewBox="0 0 200 200" className="blobsvg fluid">
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
                <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
              </linearGradient>
            </defs>
            <path className="gpath" d="" fill="url('#gradient')"></path>
          </svg>
          <svg viewBox="0 0 200 200" className="blobsvg fluid">
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
                <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
              </linearGradient>
            </defs>
            <path className="gpath" d="" fill="url('#gradient')"></path>
          </svg>
          <svg viewBox="0 0 200 200" className="blobsvg fluid">
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(90)">
                <stop id="gradientStop1" offset="0%" stopColor="var(--startColor)" />
                <stop id="gradientStop2 " offset="100%" stopColor="var(--stopColor)" />
              </linearGradient>
            </defs>
            <path className="gpath" d="" fill="url('#gradient')"></path>
          </svg>

        </div>
      </div>
    </div >
  );
}

export default Homewhatwedo;
