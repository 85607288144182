// Styles
import "./appscreens.scss";


function AppsSreens(props) {
  return (
    <div className="appscreens">
      <div className="imagearea">
        <img src={props.appscreen.image} alt="" />
      </div>


    </div>
  );
}

export default AppsSreens;
