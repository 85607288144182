import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import React, { useState, useRef } from "react";
import lottie from "lottie-web";
import logolottie from '../../assets/images/D21.json';

import insta from '../../assets/images/instagram.svg'
import twitter from '../../assets/images/twitter.svg'
import linkedin from '../../assets/images/linkedin.svg'



// Styles
import "./navbar.scss";

function Navbar(props) {
  const lottieRef = React.useRef(null);
  const locationurl = props.pathname;
  const [windowDimension, setWindowDimension] = useState(null);
  const [ismenuActive, setmenuActive] = useState("false");
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  React.useEffect(() => {
    // useEffect(() => {
    // anime({
    //   targets: '.st0, .st1',
    //   strokeDashoffset: [anime.setDashoffset, 0],
    //   easing: 'cubicBezier(.5, .05, .1, .3)',
    //   duration: 1000,
    //   loop: true,
    //   direction: 'alternate',
    //   delay: function (el, i) { return i * 50 },

    // });
    var animDuration = 20000;
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,

      animationData: logolottie
    });
    function animatebodymovin(duration) {
      const scrollPosition = window.pageYOffset;
      const maxFrames = anim.totalFrames;
      // const frame = 100 * window.pageYOffset / (document.documentElement.scrollHeight - window.innerHeight);
      const frame = 100 * window.pageYOffset / (document.documentElement.scrollHeight - window.innerHeight) / 2 * 6.35;


      anim.goToAndStop(frame, true);
    }
    const onScroll = () => {
      console.log("Scrolling");
      if (window.innerWidth >= 768) {
        animatebodymovin(animDuration);
      }
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      let totalheight = document.documentElement.scrollHeight;
      let bottom = totalheight - currentPosition;
      if (currentPosition > scrollTop) {
        //     // downscroll code

        //     setScrolling(false);
        //     if (currentPosition > (totalheight / 10) - window.innerHeight) {
        //       document.querySelectorAll('.a10').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 2) - window.innerHeight) {
        //       document.querySelectorAll('.a9').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 3) - window.innerHeight) {
        //       document.querySelectorAll('.a8').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 4) - window.innerHeight) {
        //       document.querySelectorAll('.a7').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 5) - window.innerHeight) {
        //       document.querySelectorAll('.a6').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 6) - window.innerHeight) {
        //       document.querySelectorAll('.a5').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 7) - window.innerHeight) {
        //       document.querySelectorAll('.a4').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 8) - window.innerHeight) {
        //       document.querySelectorAll('.a3').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        //     if (currentPosition > (totalheight / 10 * 9) - window.innerHeight) {
        //       document.querySelectorAll('.a2').forEach(element => {
        //         element.classList.remove('visible')
        //       });
        //     }
        if (window.pageYOffset + window.innerHeight >= document.documentElement.scrollHeight - 50) {

          // document.querySelectorAll('.logo > svg').forEach(element => {
          //   element.classList.add('dropp')
          // });
          document.querySelector('.footerlogo').classList.add('active');
        }
        if (window.pageYOffset + window.innerHeight <= document.documentElement.scrollHeight - 50) {

          // document.querySelectorAll('.a1').forEach(element => {
          //   element.classList.add('visible')
          // });
          // document.querySelectorAll('.logo > svg').forEach(element => {
          //   element.classList.remove('dropp')
          // });
          document.querySelector('.footerlogo').classList.remove('active');
        }
      } else {

        //     // upscroll code
        //     setScrolling(true);
        //     if (currentPosition < (totalheight / 10 * 9) - window.innerHeight) {
        //       document.querySelectorAll('.a2').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 8) - window.innerHeight) {
        //       document.querySelectorAll('.a3').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 7) - window.innerHeight) {
        //       document.querySelectorAll('.a4').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 6) - window.innerHeight) {
        //       document.querySelectorAll('.a5').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 5) - window.innerHeight) {
        //       document.querySelectorAll('.a6').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 4) - window.innerHeight) {
        //       document.querySelectorAll('.a7').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < (totalheight / 10 * 3) - window.innerHeight) {
        //       document.querySelectorAll('.a8').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < totalheight / 10 * 2) {
        //       document.querySelectorAll('.a9').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }
        //     if (currentPosition < document.documentElement.scrollHeight / 10) {
        //       document.querySelectorAll('.a10').forEach(element => {
        //         element.classList.add('visible')
        //       });
        //     }

        //     // 
        if (window.pageYOffset <= document.querySelector('.footer').offsetTop) {

          // document.querySelectorAll('.a1').forEach(element => {
          //   element.classList.add('visible')
          // });
          // document.querySelectorAll('.logo > svg').forEach(element => {
          //   element.classList.remove('dropp')
          // });
          document.querySelector('.footerlogo').classList.remove('active');
        }
        // setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      anim.destroy();
      document.removeEventListener("scroll", onScroll);
    };
    // }, []);

    // function onScroll() {
    // let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
    // let totalheight = document.documentElement.scrollHeight;
    // let bottom = totalheight - currentPosition;
    // if (currentPosition > scrollTop) {
    //   //     // downscroll code

    //   //     setScrolling(false);
    //   //     if (currentPosition > (totalheight / 10) - window.innerHeight) {
    //   //       document.querySelectorAll('.a10').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 2) - window.innerHeight) {
    //   //       document.querySelectorAll('.a9').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 3) - window.innerHeight) {
    //   //       document.querySelectorAll('.a8').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 4) - window.innerHeight) {
    //   //       document.querySelectorAll('.a7').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 5) - window.innerHeight) {
    //   //       document.querySelectorAll('.a6').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 6) - window.innerHeight) {
    //   //       document.querySelectorAll('.a5').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 7) - window.innerHeight) {
    //   //       document.querySelectorAll('.a4').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 8) - window.innerHeight) {
    //   //       document.querySelectorAll('.a3').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition > (totalheight / 10 * 9) - window.innerHeight) {
    //   //       document.querySelectorAll('.a2').forEach(element => {
    //   //         element.classList.remove('visible')
    //   //       });
    //   //     }

    //   if (window.pageYOffset + window.innerHeight >= document.documentElement.scrollHeight - 50) {

    //     // document.querySelectorAll('.logo > svg').forEach(element => {
    //     //   element.classList.add('dropp')
    //     // });
    //     document.querySelector('.footerlogo').classList.add('active');
    //   }

    // } else {
    //   //     // upscroll code
    //   //     setScrolling(true);
    //   //     if (currentPosition < (totalheight / 10 * 9) - window.innerHeight) {
    //   //       document.querySelectorAll('.a2').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 8) - window.innerHeight) {
    //   //       document.querySelectorAll('.a3').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 7) - window.innerHeight) {
    //   //       document.querySelectorAll('.a4').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 6) - window.innerHeight) {
    //   //       document.querySelectorAll('.a5').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 5) - window.innerHeight) {
    //   //       document.querySelectorAll('.a6').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 4) - window.innerHeight) {
    //   //       document.querySelectorAll('.a7').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < (totalheight / 10 * 3) - window.innerHeight) {
    //   //       document.querySelectorAll('.a8').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < totalheight / 10 * 2) {
    //   //       document.querySelectorAll('.a9').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }
    //   //     if (currentPosition < document.documentElement.scrollHeight / 10) {
    //   //       document.querySelectorAll('.a10').forEach(element => {
    //   //         element.classList.add('visible')
    //   //       });
    //   //     }

    //   //     // 
    //   if (currentPosition < document.querySelector('.footer').offsetTop) {
    //     // document.querySelectorAll('.a1').forEach(element => {
    //     //   element.classList.add('visible')
    //     // });
    //     // document.querySelectorAll('.logo > svg').forEach(element => {
    //     //   element.classList.remove('dropp')
    //     // });
    //     document.querySelector('.footerlogo').classList.remove('active');
    //   }
    // }

    //   setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    // }


  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 767;

  // window.onscroll = function () {
  //   if (window.scrollTop + window.clientHeight == document.documentElement.scrollHeight) {
  //     // User has scrolled to the bottom of the element
  //     console.log('bottom')
  //   }
  // }
  const togglemenu = () => {
    if (isMobile) {
      setmenuActive(!ismenuActive);
    }
  }

  const menuname = (menuname) => {
    window.localStorage.setItem('menuname', menuname);
    if (menuname == '' && !ismenuActive) {
      setmenuActive(true);
    }
  }

  const menustore = window.localStorage.getItem('menuname');

  return (
    <>
      {locationurl !== "/ourwork" ?
        <div className={!ismenuActive ? "navbar menuopen" : "navbar"}>
          <NavLink activeClassName='is-active' aria-current="page" to="/" onClick={() => menuname('')}>
            <div className="logo">
              {locationurl !== "/ourwork" ?
                <div id="logolottie" ref={lottieRef}></div> : null
                // <svg width="250px" height="250px" viewBox="0 0 250 250" version="1.1" style={{ fill: '#862ccb' }} attributeName="stroke-dashoffset" begin="0.1s;f1.end+0.4s" values="2037;0;2037" dur="15s" calcMode="linear">
                //   <g id="Group-3" transform="translate(-12.756313, -13.756313)">
                //     <g id="Group-8" transform="translate(37.000000, 37.000000)">
                //       <g id="Group-7" transform="translate(15.000000, 0.000000)">
                //         <g id="Group-2">
                //           <path className="st0 a10 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a10 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //         <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                //           <path className="st0 a9 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a9 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //       </g>
                //       <g id="Group-7" transform="translate(87.500000, 87.500000) rotate(-270.000000) translate(-87.500000, -87.500000) translate(15.000000, 0.000000)">
                //         <g id="Group-2">
                //           <path className="st0 a8 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a8 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //         <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                //           <path className="st0 a7 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a7 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //       </g>
                //     </g>
                //     <g id="Group-8" transform="translate(124.500000, 124.500000) rotate(-315.000000) translate(-124.500000, -124.500000) translate(37.000000, 37.000000)">
                //       <g id="Group-7" transform="translate(15.000000, 0.000000)">
                //         <g id="Group-2">
                //           <path className="st0 a6 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a6 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //         <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                //           <path className="st0 a5 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a5 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //       </g>
                //       <g id="Group-7" transform="translate(87.500000, 87.500000) rotate(-270.000000) translate(-87.500000, -87.500000) translate(15.000000, 0.000000)">
                //         <g id="Group-2">
                //           <path className="st0 a4 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a3 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //         <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                //           <path className="st0 a2 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //           <path className="st0 a1 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //         </g>
                //       </g>
                //     </g>
                //   </g>

                // </svg>
                // :
                // <svg width="250px" height="250px" viewBox="0 0 228 227" version="1.1" style={{ fill: '#d2d2bc' }}>


                //   <g id="Group-3" transform="translate(-2.000000, -3.000000)">
                //     <g id="Group-2" transform="translate(41.500000, 26.000000)">
                //       <path className="st0 a10 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a10 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(114.000000, 156.000000) scale(1, -1) translate(-114.000000, -156.000000) translate(41.500000, 111.000000)">
                //       <path className="st0 a9 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a9 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(156.500000, 113.500000) rotate(-270.000000) translate(-156.500000, -113.500000) translate(84.000000, 68.500000)">
                //       <path className="st0 a8 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a8 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(71.500000, 113.500000) scale(1, -1) rotate(270.000000) translate(-71.500000, -113.500000) translate(-1.000000, 68.500000)">
                //       <path className="st0 a7 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a7 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(144.052038, 83.447962) rotate(-315.000000) translate(-144.052038, -83.447962) translate(71.552038, 38.447962)">
                //       <path className="st0 a6 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a6 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(83.947962, 143.552038) scale(1, -1) rotate(315.000000) translate(-83.947962, -143.552038) translate(11.447962, 98.552038)">
                //       <path className="st0 a5 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a5 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(144.052038, 143.552038) rotate(-585.000000) translate(-144.052038, -143.552038) translate(71.552038, 98.552038)">
                //       <path className="st0 a4 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a3 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //     <g id="Group-2" transform="translate(83.947962, 83.447962) scale(1, -1) rotate(585.000000) translate(-83.947962, -83.447962) translate(11.447962, 38.447962)">
                //       <path className="st0 a2 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                //       <path className="st0 a1 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                //     </g>
                //   </g>
                // </svg>
              }
            </div>
          </NavLink>

          {menustore ?
            <div className="mobilemenuname">

              {menustore}

            </div>
            : null
          }
          <div className={locationurl !== "/ourwork" ? "menues" : "menues greymenues"} onClick={togglemenu}>
            <ul>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/whatwedo" onClick={() => menuname('What We Do')}>What We Do</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/whoweare" onClick={() => menuname('Who We Are')}>Who We Are</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/ourwork" onClick={() => menuname('Our Work')}>Our Work</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/contact" onClick={() => menuname('Collaborate')}>Collaborate</NavLink>
              </li>
              <li className="mobilesocialheader">
                <a href="#"><img src={insta} alt="" /></a>
                <a href="#"><img src={twitter} alt="" /></a>
                <a href="#"><img src={linkedin} alt="" /></a>
              </li>
            </ul>
          </div>
          <div className="mobilemenu" onClick={togglemenu}>
            Menu
          </div>

        </div>
        :
        <div className={!ismenuActive ? "navbar navbarother menuopen" : "navbar navbarother"}>
          <NavLink activeClassName='is-active' aria-current="page" to="/" onClick={() => menuname('')}>
            <div className="logo">
              {locationurl !== "/ourwork" ?
                <svg width="250px" height="250px" viewBox="0 0 250 250" version="1.1" style={{ fill: '#862ccb' }}>
                  <g id="Group-3" transform="translate(-12.756313, -13.756313)">
                    <g id="Group-8" transform="translate(37.000000, 37.000000)">
                      <g id="Group-7" transform="translate(15.000000, 0.000000)">
                        <g id="Group-2">
                          <path className="st0 a10 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a10 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                        <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                          <path className="st0 a9 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a9 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                      </g>
                      <g id="Group-7" transform="translate(87.500000, 87.500000) rotate(-270.000000) translate(-87.500000, -87.500000) translate(15.000000, 0.000000)">
                        <g id="Group-2">
                          <path className="st0 a8 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a8 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                        <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                          <path className="st0 a7 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a7 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                      </g>
                    </g>
                    <g id="Group-8" transform="translate(124.500000, 124.500000) rotate(-315.000000) translate(-124.500000, -124.500000) translate(37.000000, 37.000000)">
                      <g id="Group-7" transform="translate(15.000000, 0.000000)">
                        <g id="Group-2">
                          <path className="st0 a6 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a6 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                        <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                          <path className="st0 a5 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a5 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                      </g>
                      <g id="Group-7" transform="translate(87.500000, 87.500000) rotate(-270.000000) translate(-87.500000, -87.500000) translate(15.000000, 0.000000)">
                        <g id="Group-2">
                          <path className="st0 a4 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a3 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                        <g id="Group-2" transform="translate(72.500000, 130.000000) scale(1, -1) translate(-72.500000, -130.000000) translate(0.000000, 85.000000)">
                          <path className="st0 a2 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                          <path className="st0 a1 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                        </g>
                      </g>
                    </g>
                  </g>

                </svg>
                :
                <svg width="250px" height="250px" viewBox="0 0 228 227" version="1.1" style={{ fill: '#d2d2bc' }}>


                  <g id="Group-3" transform="translate(-2.000000, -3.000000)">
                    <g id="Group-2" transform="translate(41.500000, 26.000000)">
                      <path className="st0 a10 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a10 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(114.000000, 156.000000) scale(1, -1) translate(-114.000000, -156.000000) translate(41.500000, 111.000000)">
                      <path className="st0 a9 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a9 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(156.500000, 113.500000) rotate(-270.000000) translate(-156.500000, -113.500000) translate(84.000000, 68.500000)">
                      <path className="st0 a8 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a8 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(71.500000, 113.500000) scale(1, -1) rotate(270.000000) translate(-71.500000, -113.500000) translate(-1.000000, 68.500000)">
                      <path className="st0 a7 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a7 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(144.052038, 83.447962) rotate(-315.000000) translate(-144.052038, -83.447962) translate(71.552038, 38.447962)">
                      <path className="st0 a6 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a6 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(83.947962, 143.552038) scale(1, -1) rotate(315.000000) translate(-83.947962, -143.552038) translate(11.447962, 98.552038)">
                      <path className="st0 a5 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a5 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(144.052038, 143.552038) rotate(-585.000000) translate(-144.052038, -143.552038) translate(71.552038, 98.552038)">
                      <path className="st0 a4 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a3 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                    <g id="Group-2" transform="translate(83.947962, 83.447962) scale(1, -1) rotate(585.000000) translate(-83.947962, -83.447962) translate(11.447962, 38.447962)">
                      <path className="st0 a2 visible" d="M36.3946784,0 C56.8398007,0 73.4525619,16.359355 73.7827726,36.6650239 L73.7877822,37.2815424 L73.7877822,89.0276008 L70.9768486,89.0276008 L70.9769934,74.5948957 C62.4095816,83.7339586 51.1843963,88.3167463 37.3932841,88.3167463 C22.0878062,88.3167463 9.69392157,82.6739774 0.32443903,71.4260633 C-0.203761801,70.7919666 -0.0682031103,69.8516407 0.594335772,69.3864135 L0.692554314,69.3232688 L69.8967676,28.6983237 C66.0705341,13.8087677 52.521771,2.80254777 36.3946784,2.80254777 C23.8886662,2.80254777 12.6014466,9.65458113 6.75571971,20.4055359 L6.58386233,20.727441 L6.53391065,20.8502717 L5.67966517,22.8783048 L3.08708324,21.7953285 L4.05712243,19.5010716 L4.09067901,19.4312376 C10.3647795,7.57810648 22.7069102,0 36.3946784,0 Z M70.5081603,31.5915447 L3.56594336,70.8887643 L3.72213762,71.063626 C12.2833984,80.5920307 23.3095708,85.3976897 36.8951101,85.5121056 L37.3932841,85.5141985 C51.5555088,85.5141985 62.7105644,80.4649263 70.9764283,70.3323266 L70.9768486,37.2815424 C70.9768486,35.3433662 70.8164482,33.4425418 70.5081603,31.5915447 Z" id="Combined-Shape-Copy"></path>
                      <path className="st0 a1 visible" d="M107.394678,0 C127.839801,0 144.452562,16.359355 144.782773,36.6650239 L144.787782,37.2815424 L144.787782,89.0276008 L141.976849,89.0276008 L141.976993,74.5948957 C133.409582,83.7339586 122.184396,88.3167463 108.393284,88.3167463 C93.0878062,88.3167463 80.6939216,82.6739774 71.324439,71.4260633 C70.7962382,70.7919666 70.9317969,69.8516407 71.5943358,69.3864135 L71.6925543,69.3232688 L140.896768,28.6983237 C137.070534,13.8087677 123.521771,2.80254777 107.394678,2.80254777 C94.8886662,2.80254777 83.6014466,9.65458113 77.7557197,20.4055359 L77.5838623,20.727441 L77.5339107,20.8502717 L76.6796652,22.8783048 L74.0870832,21.7953285 L75.0571224,19.5010716 L75.090679,19.4312376 C81.3647795,7.57810648 93.7069102,0 107.394678,0 Z M141.50816,31.5915447 L74.5659434,70.8887643 L74.7221376,71.063626 C83.2833984,80.5920307 94.3095708,85.3976897 107.89511,85.5121056 L108.393284,85.5141985 C122.555509,85.5141985 133.710564,80.4649263 141.976428,70.3323266 L141.976849,37.2815424 C141.976849,35.3433662 141.816448,33.4425418 141.50816,31.5915447 Z" id="Combined-Shape-Copy" transform="translate(107.893891, 44.513800) scale(-1, 1) translate(-107.893891, -44.513800) "></path>
                    </g>
                  </g>
                </svg>
              }
            </div>
          </NavLink>
          {menustore ?
            <div className="mobilemenuname">

              {menustore}

            </div>
            : null
          }
          <div className={locationurl !== "/ourwork" ? "menues" : "menues greymenues"} onClick={togglemenu}>
            <ul>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/whatwedo" onClick={() => menuname('What We Do')}>What We Do</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/whoweare" onClick={() => menuname('Who We Are')}>Who We Are</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/ourwork" onClick={() => menuname('Our Work')}>Our Work</NavLink>
              </li>
              <li>
                <NavLink activeClassName='is-active' aria-current="page" to="/contact" onClick={() => menuname('Collaborate')}>Collaborate</NavLink>
              </li>
              <li className="mobilesocialheader">
                <a href="#"><img src={insta} alt="" /></a>
                <a href="#"><img src={twitter} alt="" /></a>
                <a href="#"><img src={linkedin} alt="" /></a>
              </li>
            </ul>
          </div>
          <div className="mobilemenu" onClick={togglemenu}>
            Menu
          </div>

        </div>
      }
    </>
  );
}

export default Navbar;
