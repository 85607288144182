import { NavLink } from "react-router-dom";

import safer from '../../../assets/images/ourwork/safer.png'
import tweaky from '../../../assets/images/ourwork/tweaky.png'
import norcalachd from '../../../assets/images/ourwork/norcalachd.png'
import glicrx from '../../../assets/images/ourwork/glicrx.png'
import dashboard from '../../../assets/images/ourwork/dashboard.png'
import kalvie from '../../../assets/images/ourwork/kalvie.png'




// Styles
import "./projects.scss";


function Projects(props) {
  const projectlist = [
    {
      name: "Safer",
      link: "https://safer.io/",
      image: safer,
      description: "Building a product site that helps to amplify Thorn’s mission: help children be safer. Safer protects platforms from abuse content at scale.",
      tag: ["Website", "Animation", "Ghost", "Mobile", "???", "???"],
      viewlink:"/safer"
    },
    {
      name: "Tweaky",
      link: "https://tweaky.io/",
      image: tweaky,
      description: "Everyone has a site, and usually it needs tweaking. Whether you need a plug-in update or monthly SEO report Tweaky is here for you.",
      tag: ["Website", "Mobile", "???", "???"],
      viewlink:"/tweaky"
    },
    {
      name: "Norcaladhd",
      link: "https://www.norcaladhd.com/",
      image: norcalachd,
      description: "Helping to bring stigma-free adult ADHD care to the masses. Another sentence here about the code, or integrations or w/e this build required.",
      tag: ["Website", "Mobile", "???"],
      viewlink:"/norcaladhd"
    },
    {
      name: "Glicrx",
      link: "https://glicrx.com/",
      image: glicrx,
      description: "Lorem ipsum dolor sit amet, ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpre.",
      tag: ["Website", "Mobile", "App", "???"],
      viewlink:"/glicrx"
    },
    {
      name: "Kalvie",
      link: "https://kalvie.com/",
      image: kalvie,
      description: "Lorem ipsum dolor sit amet, ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpre.",
      tag: ["Website", "Mobile", "App", "???"],
      viewlink:"/kalvie"
    },
    {
      name: "Glic Dashboard",
      link: "https://reporting.glichealth.com",
      image: dashboard,
      description: "The Honest Discounts custom reporting dashboard streamlines millions of data points for thousands of users all around the country.",
      tag: ["App Development", "Data Intelligence", "Data Visualization", "React", "ChartJS"],
      viewlink:"/dashboard"
    }
  ]

  return (
    <div className="Viewourwork_work">
      <div className="titlesec">
        <div className="maintitle">
          <p>Our work is rewarding, but our collaborations are more so.</p>
        </div>
      </div>
      <div className="titlesec">
        {projectlist.map((data, index) => (
          <div className="maintitle" key={index+'c'}>
            <a href={data.link} target="_blank" rel="noreferrer">Visit Site</a>
            <div className="imageareparent">

              <div className="imagearea">
                <img src={data.image} alt=""/>
                {/* <a href="" onClick={e => e.preventDefault()} className="view">View Project</a> */}
                <NavLink className="view" aria-current="page" to={`/ourwork`+data.viewlink}>View Project</NavLink>
                <div className="shortdesc">
                  {data.description}
                </div>
                <div className="labelbox">
                  {data.tag.map((tag) => (
                    <div>{tag}</div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Projects;
