// import { ReactComponent as appdev } from '../../../assets/images/whatwedo/appdev.svg'
// import { ReactComponent as automatingbusiness } from '../../../assets/images/whatwedo/automatingbusiness.svg'
// import { ReactComponent as thirdparty } from '../../../assets/images/whatwedo/thirdparty.svg'
// import { ReactComponent as dataintelligence } from '../../../assets/images/whatwedo/dataintelligence.svg'
// import { ReactComponent as digitalonline } from '../../../assets/images/whatwedo/digitalonline.svg'

import React, { useState, useRef, createRef } from "react";
import lottie from "lottie-web";
import icon1 from '../../../assets/images/whatwedo/Icons/Pre-comp 1 App Dev.json'
import icon2 from '../../../assets/images/whatwedo/Icons/Pre-comp 2 3rd party intg.json'
import icon3 from '../../../assets/images/whatwedo/Icons/Pre-comp 3 Auto biz flows.json'
import icon4 from '../../../assets/images/whatwedo/Icons/Pre-comp 4 Analytics.json'
import icon5 from '../../../assets/images/whatwedo/Icons/Pre-comp 5 Online digi presence.json'

// Styles
import "./WhatwedoHero.scss";

function Whatwedohero(props) {
  // const lottieRef = React.useRef(0);
  const whatwedoservices = [
    {
      img: icon1,
      title: 'App development',
      description: 'Statement here about app dev. Lorem ipsum dolor sit amet, an pro tale similique, sea an nostrud eripuit accusamus. Persius salutandi an has, sed munere delenit pertinax eu. Qui scaevola deterruisset ex, sit falli simul option ne. Ne melius feugait eum, cu omnis corrumpit dignissim duo, inermis admodum appellantur vel et.'
    },
    {
      img: icon2,
      title: 'Automating business workflows',
      description: 'Statement here about app dev. Lorem ipsum dolor sit amet, an pro tale similique, sea an nostrud eripuit accusamus. Persius salutandi an has, sed munere delenit pertinax eu. Qui scaevola deterruisset ex, sit falli simul option ne. Ne melius feugait eum, cu omnis corrumpit dignissim duo, inermis admodum appellantur vel et.'
    },
    {
      img: icon3,
      title: 'Third-party integrations',
      description: 'Statement here about app dev. Lorem ipsum dolor sit amet, an pro tale similique, sea an nostrud eripuit accusamus. Persius salutandi an has, sed munere delenit pertinax eu. Qui scaevola deterruisset ex, sit falli simul option ne. Ne melius feugait eum, cu omnis corrumpit dignissim duo, inermis admodum appellantur vel et.'
    },
    {
      img: icon4,
      title: 'Data intelligence, visualizations, & analytics',
      description: 'Statement here about app dev. Lorem ipsum dolor sit amet, an pro tale similique, sea an nostrud eripuit accusamus. Persius salutandi an has, sed munere delenit pertinax eu. Qui scaevola deterruisset ex, sit falli simul option ne. Ne melius feugait eum, cu omnis corrumpit dignissim duo, inermis admodum appellantur vel et.'
    },
    {
      img: icon5,
      title: 'Digital online presence',
      description: 'Statement here about app dev. Lorem ipsum dolor sit amet, an pro tale similique, sea an nostrud eripuit accusamus. Persius salutandi an has, sed munere delenit pertinax eu. Qui scaevola deterruisset ex, sit falli simul option ne. Ne melius feugait eum, cu omnis corrumpit dignissim duo, inermis admodum appellantur vel et.'
    }
  ];
  // const parentRef   = useRef(null);
  const [currentIndex, setIndex] = useState();
  const elementsRef = useRef(whatwedoservices.map(() => createRef()));
  let closee = false;
  const iconsref = useRef(whatwedoservices.map(() => createRef()));

  const setIndexx = (data) => {
    setTimeout(() => {
      if (elementsRef.current[data].current.classList !== 'active' && closee !== true) {
        elementsRef.current[data].current.classList.add('active');
        // elementsRef.current[data]
      }
      else {

      }
    }, 300);
  }

  const close = () => {
    elementsRef.current.map(
      ref => {

        if (ref.current.classList === 'active') {
          closee = true;
          ref.current.classList.remove('active')
          setTimeout(() => {
            closee = false;
          }, 600);
        }
      }
    );
  }
  React.useEffect(() => {
    if (document.getElementById('icons0').children.length === 0) {
      whatwedoservices.map((data, index) => {
        const anim = lottie.loadAnimation({
          container: iconsref.current[index].current,
          animationData: data.img,
          autoplay: false
        });
        // const animm = lottie.loadAnimation({
        //   container: iconsref.current[index].current,
        //   animationData: data.img,
        //   autoplay: true
        // });
        anim.goToAndStop(120, true);
      })
      
    }
  })

  return (
    <div className="Whatwedohero">
      <div className="parent">
        <h1>We use technology to help transform your business. </h1>
        <div className="serviceparentbox">
          {whatwedoservices.map((data, index) => (
            <div key={index} ref={elementsRef.current[index]} onClick={() => { setIndex(index); setIndexx(index) }} className={currentIndex === index ? "active" : ''}>
              {/* <data.img /> */}
              <span id={`icons` + index} ref={iconsref.current[index]}
></span>
              <p>{data.title}<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="ahmrit_what-we-do_open" transform="translate(-655.000000, -635.000000)" stroke="#862CCB" stroke-width="3">
                    <g id="Group-24" transform="translate(657.000000, 637.000000)">
                      <polyline id="Path" transform="translate(11.000000, 11.000000) rotate(-180.000000) translate(-11.000000, -11.000000) " points="0 0 22 0 22 22"></polyline>
                      <line x1="0" y1="22" x2="22" y2="0" id="Path-4" stroke-linejoin="round" transform="translate(11.000000, 11.000000) rotate(-180.000000) translate(-11.000000, -11.000000) "></line>
                    </g>
                  </g>
                </g>
              </svg></p>
              <div className="content">
                {data.description}
                <span className="close" onClick={() => { close(); }}>Close</span>

              </div>
              <div className="hidden" >{data.description}</div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default Whatwedohero;