import { NavLink } from "react-router-dom";
import Homework from '../../../assets/images/homeviewworkimg.png'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



// Styles
import "./viewourwork.scss";


function Viewourwork(props) {
  const carsouelitem = [
    {
      "img": Homework,
      "project": "Safer — ",
      "description": "Building a product site that helps to amplify Thorn’s mission: help children be safer. "
    },
    {
      "img": Homework,
      "project": "Safer — ",
      "description": "Building a product site that helps to amplify Thorn’s mission: help children be safer. "
    },
    {
      "img": Homework,
      "project": "Safer — ",
      "description": "Building a product site that helps to amplify Thorn’s mission: help children be safer. "
    },
    {
      "img": Homework,
      "project": "Safer — ",
      "description": "Building a product site that helps to amplify Thorn’s mission: help children be safer. "
    }
  ]
  return (
    <div className="Viewourwork">
      <div className="titlesec">
        <div className="maintitle">
          <NavLink aria-current="page" to="/ourwork">View Our Work</NavLink>
          <div className="imagearea">
            <Carousel showThumbs={false} dots={false} showStatus={true}>
              {carsouelitem.map((data, index) => (
                <div>
                  <img src={Homework} alt=""/>
                  <div className="sidercontent">

                    <span>Safer —</span>
                    Building a product site that helps to amplify Thorn’s mission: help children be safer.
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Viewourwork;
