import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Whowearehero from '../../components/whoweare/hero'
import Team from '../../components/whoweare/team'
import Clock from '../../components/whoweare/clock'
import Contactwhoweare from '../../components/whoweare/contact'
// Styles
import "./Whoweare.scss";

function Whoweare() {
  return (
    <div className="whowearepage">
      <Navbar />
      <Whowearehero />
      <div className="clockbox">
        <Clock />
      </div>
      <Team/>
      <Contactwhoweare/>
      <Footer />
    </div>
  );
}

export default Whoweare;
