// import { NavLink } from "react-router-dom";

// Styles
import "./ProjectHero.scss";


function ProjectHero(props) {

  return (
    <div className="ProjectHero">
      <div className="titlesec">
        <div className="maintitle">
          <p>{props.projecthero.herotitle}</p>
        </div>
      </div>
      <div className="projectabout">
        <div className="client">
          <div className="title">Client</div>
          <div className="content">{props.projecthero.client}</div>
        </div>
        <div className="brief">
          <div className="title">Brief</div>
          <div className="content">{props.projecthero.brief}</div>
        </div>
        <div className="credits">
          <div className="title">Credits</div>
          <div className="content">
            <p><span>Copy: </span>{props.projecthero.credits[0].copy}</p>
            <p><span> Art direction & illustration: </span>{props.projecthero.credits[0].art}</p>
            <p><span>Site Build: </span>{props.projecthero.credits[0].build}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ProjectHero;
