import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Projects from '../../components/ourwork/projects'

// Styles
import "./ourwork.scss";

function OurWork() {
  return (
    <div className="ourworkpage">
      <Navbar pathname={'/ourwork'}/>
      <Projects/>
      <Footer />
    </div>
  );
}

export default OurWork;
