import React from "react";
import styled from "styled-components";
// import useClock from "./useClock";
// import sun from '../../../../src/assets/images/sun.svg';
// import moon from '../../../../src/assets/images/moon.svg';
// import { Circle } from "./ui";

// const SecondHand = styled.div`
//   position: absolute;
//   width: 1px;
//   height: ${ props => 150 - 10 - props.borderWidth }px;
//   background: black;
//   border-radius: 100px;
//   top: 10px;
//   left: 50%;
//   transform: rotate(${props => props.rotation || "30deg"})
//     translate3d(-50%, 0, 0);
//   transform-origin: 0px bottom;
// `;

const MinuteHand = styled.div`
  position: absolute;
  width: 2px;
  height: ${props => 55 - 10 - props.borderWidth}px;
  background: #fff;
  border-radius: 10px;
  top: 9px;
  left: 50%;
  transform: rotate(${props => props.rotation || "30deg"})
    translate3d(-50%, 0, 0);
  transform-origin: 0px bottom;
`;

const HourHand = styled.div`
  position: absolute;
  width: 3.6px;
  height: ${props => 55 - 12 - props.borderWidth}px;
  background: #fff;
  border-radius: 10px;
  top: 11px;
  left: 50%;
  transform: rotate(${props => props.rotation || "30deg"})
    translate3d(-50%, 0, 0);
  transform-origin: 0px bottom;
`;

// const Dot = styled(Circle)`
//   position: absolute;
//   background: black;
//   top: 50%;
//   left: 50%;
//   transform: translate3d(-50%, -50%, 0);
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
// `;

export default ({ time, borderWidth = 10 }) => {
  // debugger;
  return (
    <div>
      {/* <SecondHand borderWidth={borderWidth} rotation={time.getSeconds() * 6 + "deg"} /> */}
      <MinuteHand
        borderWidth={borderWidth}
        rotation={(time.getMinutes() + time.getSeconds() / 60) * 6 + "deg"}
      />
      <HourHand
        borderWidth={borderWidth}
        rotation={
          ((time.getHours() % 12) + time.getMinutes() / 60) * 30 + "deg"
        }

      />
     

      {/* <Dot size={10} /> */}
    </div >
  );
};
