// Styles
import "./homeHero.scss";
import React, { useState, useRef } from "react";
import lottie from "lottie-web";
import Pool from '../../../assets/images/pool.json';

function HomeHero(props) {
  const lottieRef = React.useRef(null);
  React.useEffect(() => {
    var animDuration = 10000;
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      animationData: Pool
    });
    // function animatebodymovin(duration) {
    //   const scrollPosition = window.pageYOffset;
    //   const maxFrames = anim.totalFrames;
    //   const frame = [0, maxFrames];
    //   anim.goToAndStop(frame, true);
    // }
    // animatebodymovin(animDuration);
  })
  return (
    <div className="HeroSec">
      <div className="parent">
        <h1>We’re ahmrit, a full-stack dev studio. </h1>
        <div className="herosub">
          <p>In Sanskrit ahmrit (pronounced <i>uh</i> m-rit) means nectar — the sweet liquid from a flower.</p>
        </div>
        <div className="drop-parent">
          <div className="dropbox">
            {/* <div className="drop"></div>
            <div className="wave"></div> */}
            <div id="pool" ref={lottieRef}></div>

          </div>

        </div>

      </div>

    </div>
  );
}

export default HomeHero;
