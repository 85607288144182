// Styles
import "./whowearehero.scss";

function Whowearehero(props) {


  return (
    <div className="HeroSecwhoweare">
      <div className="parent">
        <h1>We’re a team of passionate creatives, excited about problem solving, and making cool <span>things</span>.</h1>
        <div className="herosub">
          <p>We’re located in two countries (opposite clocks) and the workflow between locations has proven to be helpful. You review during your workday, we make edits while you sleep. 24hr utilization. </p>
        </div>
      </div>
    </div>
  );
}

export default Whowearehero;
