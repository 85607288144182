import Navbar from '../../components/navbar'
import Whatwedohero from '../../components/whatwedo/hero'
import Technologieswhatwedo from '../../components/whatwedo/technologies'
import Viewourwork from '../../components/whatwedo/viewourwork'
import Footer from '../../components/footer'
// Styles
import "./whatwedo.scss";

function Whatwedo() {
  return (
    <div className="whatwedopage">
      <Navbar />   
      <Whatwedohero/>   
      <Technologieswhatwedo/>
      <Viewourwork/>
      <Footer/>
    </div>
  );
}

export default Whatwedo;
