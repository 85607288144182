// import { NavLink } from "react-router-dom";

// import safer from '../../../assets/images/ourwork/safer.png'
// import tweaky from '../../../assets/images/ourwork/tweaky.png'
// import norcalachd from '../../../assets/images/ourwork/norcalachd.png'
// import glicrx from '../../../assets/images/ourwork/glicrx.png'
// import kalvie from '../../../assets/images/ourwork/kalvie.png'




// Styles
import "./projectsherobelow.scss";


function ProjectsHerobelow(props) {
  return (
    <div className="projectsherobelow">
      <div className="titlesec">

        <div className="maintitle">
          <a href={props.herobelow.link} target="_blank" rel="noreferrer">Visit Site</a>
          <div className="imageareparent">
            <div className="imagearea">
              <img src={props.herobelow.herobelowimage} alt="" />
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export default ProjectsHerobelow;
