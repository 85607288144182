import { NavLink } from "react-router-dom";
import footerLogo from '../../assets/images/footerLogo.svg'
import footerLogowork from '../../assets/images/flogowork.svg'
import insta from '../../assets/images/instagram.svg'
import instagrey from '../../assets/images/instagramgrey.svg'
import twitter from '../../assets/images/twitter.svg'
import twittergrey from '../../assets/images/twittergrey.svg'
import linkedin from '../../assets/images/linkedin.svg'
import linkedingrey from '../../assets/images/linkedingrey.svg'



// Styles
import "./footer.scss";

function Footer(props) {
  const locationurl = window.location.pathname.split('/')[1];
  return (
    <div className={locationurl !== "ourwork" ? "footer" : "footer footer_greys"}>
      <div className="footerlogo logo">
        <NavLink activeClassName='is-active' aria-current="page" to="/">
          {locationurl !== "ourwork" ?
            <img src={footerLogo} alt="" />
            :
            <img src={footerLogowork} alt="" />
          }
        </NavLink>
      </div>
      <div className="rightsec">
        <ul className="links">
          <li><NavLink activeClassName='is-active' data-title="The things we make inevitably," aria-current="page" to="/whatwedo">[What We Do]</NavLink></li>
          <li><NavLink activeClassName='is-active' data-title="define who we are." aria-current="page" to="/whoweare">[Who We Are]</NavLink></li>
          <li><NavLink activeClassName='is-active' data-title="And our best project — is the next one." aria-current="page" to="/ourwork">[Our Work]</NavLink></li>
          <li><NavLink activeClassName='is-active' data-title="So let’s make that yours and keep in mind," aria-current="page" to="/contact">[Collaborate]</NavLink></li>
          <li><NavLink aria-current="page" data-title="our future’s future." to="/">[Careers]</NavLink></li>
        </ul>
        <ul className="text">
          <li>The things we make inevitably,</li>
          <li>define who we are.</li>
          <li>And our best project — is the next one.</li>
          <li>So let’s make that yours and keep in mind,</li>
          <li>our future’s future.</li>
        </ul>
        <ul className="social">
          <li><a href="#"><img alt="" src={locationurl !== "ourwork" ? insta : instagrey} /></a></li>
          <li><a href="#"><img alt="" src={locationurl !== "ourwork" ? twitter : twittergrey} /></a></li>
          <li><a href="#"><img alt="" src={locationurl !== "ourwork" ? linkedin : linkedingrey} /></a></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
