// import {Switch, Route, BrowserRouter} from "react-router-dom";

import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import Navbar from './components/navbar';
// import Careers from "./containers/careers";
import Home from "./containers/home";
import Whatwedo from './containers/whatwedo'
import Whoweare from './containers/whoweare'
import ContactPage from './containers/contact'
import OurWork from './containers/ourwork'
import Project from './containers/project'
// import Investment from "./containers/investment";
// import Aboutus from "./containers/aboutus";
// import Product from "./containers/product";
// import JobDetail from "./containers/jobdetail";
// import NotFound from "./containers/notFound";
// import Contact from "./containers/contact";

function AppRouter() {
  const routes = [
    { path: '/', name: 'Home', Component: Home },
    { path: '/whatwedo', name: 'whatwedo', Component: Whatwedo},
    { path: '/whoweare', name: 'whoweare', Component: Whoweare },
    { path: '/contact', name: 'contact', Component: ContactPage },
    { path: '/ourwork', name: 'ourwork', Component: OurWork },
    { path: '/ourwork/:project', name: 'ourwork', Component: Project }
  ]
  return (
    <div style={{}}>
      {/* <BrowserRouter> */}
      {/* <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/whatwedo" component={Whatwedo} />
          <Route exact path="/whoweare" component={Whoweare} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/ourwork" component={OurWork} /> */}

      {/* <Route exact path="/investment" component={Investment} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/product" component={Product} />
          <Route exact path="/notfound" component={NotFound} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/careers/:name" render={(props) => <JobDetail   {...props} />} /> */}
      {/* </Switch> */}


      {/* </BrowserRouter> */}
      <Router>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={300}
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          )}
        </Route>
         ))}
      </Router>
    </div>
  );
}

export default AppRouter;
