import React from "react";
import styled from "styled-components";

import A from "./A";
import B from "./B";

const ClockWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 2rem 0 0;
    font-family: Raleway;
    font-size: 1.25rem;
    font-weight: 300;
  }

  @media (max-width: 700px) {
    width: 100%;
    padding: 4rem 0;
  }
`;

const ClockGrids = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 700px) {
    padding: 1rem;
  }
`;


function Clock() {
  return (
    <>

      <ClockGrids>
        <div>
          <ClockWrapper>
            <B size={110} />
          </ClockWrapper>
          <ClockWrapper>
            <A size={110} />
          </ClockWrapper>
        </div>
      </ClockGrids>
    </>
  );
}
export default Clock;
