// import { NavLink } from "react-router-dom";

// Styles
import "./kalviesingle.scss";


function Kalviesingle(props) {

  return (
    <div className="kalviesingle">
      <div>
        <img src={props.screen.image} alt="" />
      </div>

    </div>
  );
}

export default Kalviesingle;
