// import { NavLink } from "react-router-dom";
// import Homework from '../../../assets/images/homeviewworkimg.png'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



// Styles
import "./DashboardSlides.scss";


function DashboardSlides(props) {
  return (
    <div className="dashboardslide">
      <div className="titlesec">
        <div className="maintitle">
          <div className="imagearea">
            <Carousel showThumbs={false} dots={false} showStatus={true}>
              {props.carousel.map((data, index) => (
                <div>
                  <img src={data.img} alt="" />
                  <div className="sidercontent">

                    <span>{data.project}</span>
                    {data.description}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

    </div>
  );
}

export default DashboardSlides;
