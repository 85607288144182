import { Redirect } from "react-router-dom"; //import Redirect first

import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import ProjectHero from '../../components/project/hero'
import ProjectsHerobelow from '../../components/project/herobelowproject'
import AppsSreens from '../../components/project/appscreens'
import Languages from '../../components/project/languages'
import NextProject from '../../components/project/nextproject'
import Smallpart from '../../components/project/smallpart'
import Kalviesingle from '../../components/project/kalviesingle'
import DashboardSlides from '../../components/project/dashboardslides'

// Images
import safer from '../../../src/assets/images/ourwork/safer.png'
import herobelowtweaky from '../../../src/assets/images/ourwork/herobelowtweaky.png'
import herobelownoralachd from '../../../src/assets/images/ourwork/norcalachd.png'
import herobelowglicrx from '../../../src/assets/images/ourwork/glicrx.png'
import herobelowkalive from '../../../src/assets/images/ourwork/kalvie.png'
import kalviescreen from '../../../src/assets/images/ourwork/kalviescreen.png'
import dashboardherobelow from '../../../src/assets/images/ourwork/dashboardherobelow.png'
import dashboardslide from '../../../src/assets/images/ourwork/dashboard.png'
import saferappscreens from '../../../src/assets/images/ourwork/saferappscreens.png'
import norcalappscreen from '../../../src/assets/images/ourwork/norcalappscreen.png'
import glicappscreen from '../../../src/assets/images/ourwork/glicappscreen.png'
import tweakynext from '../../../src/assets/images/nextproject/tweakynext.png'
import norcaladhdnext from '../../../src/assets/images/nextproject/norcaladhdnext.png'
import glicrxnext from '../../../src/assets/images/nextproject/glicrxnext.png'
import dashboardnext from '../../../src/assets/images/nextproject/dashboardnext.png'
import safernext from '../../../src/assets/images/nextproject/safernext.png'
import kalvienext from '../../../src/assets/images/nextproject/kalvienext.png'
import tweakyright1 from '../../../src/assets/images/ourwork/tweakyright1.png'
import tweakyleft1 from '../../../src/assets/images/ourwork/tweakylef1.png'
import kalvieright1 from '../../../src/assets/images/ourwork/kalvieright1.png'
import kalvieleft1 from '../../../src/assets/images/ourwork/kalvieleft1.png'
import kalvieright2 from '../../../src/assets/images/ourwork/kalvieright2.png'

// Styles
import "./Projectpage.scss";

function Project() {
  const locationName = window.location.pathname.split('/')[2];
  if(locationName !== "safer" && locationName !== "tweaky" && locationName !== "norcaladhd"&& locationName !== "glicrx" && locationName !== "kalvie" && locationName !== "dashboard") {
    return <Redirect to="/" />
  }
  const projects = [
    {
      "safer": [
        {
          "Herosec": [
            {
              "herotitle": "Building a product site to amplify Thorn’s mission: help children be safer.",
              "client": "Thorn",
              "brief": "Safer is a complete pipeline tool to empower companies to proactively identify, remove and report child sexual abuse material (CSAM) from their platforms. We were hired to build their product site meeting the following needs: _______ integration,  another example, another example.",
              "credits": [
                {
                  "copy": "Sarah Potts",
                  "art": "Kelsey Lesko",
                  "build": "Abhay Singh"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": safer,
              "link": "https://safer.io/",
            }
          ],
          "appscreen": [
            {
              "image": saferappscreens
            }
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery"],
              "backend": ["Ghost", "Hubspot Forms API"]
            }
          ],
          "nextproj": [
            {
              "title": "Tweaky",
              "text": "Everyone has a site, and usually it needs tweaking. Tweaky’s new front-end and automated backend lets them focus on what they do best.",
              "nextprojimage": tweakynext,
              "nextlink": "tweaky"
            }
          ]

        }
      ],
      "tweaky": [
        {
          "Herosec": [
            {
              "herotitle": "Everyone has a site, and usually it needs tweaking.",
              "client": "Tweaky.io",
              "brief": "Tweaky offers one-off and subscription based website tweaks for folks that run their business on platforms like Wordpress, Shopify, and Squarespace, who need on-demand help from tech experts to get things where they want them. ahmrit was tasked with building a high-performance website and creating an automated business workflow that included Intercom/Drift Chat for customer support, Chargedesk for billing, and Hubspot for customer relations, all strung together with Trello for project management.",
              "credits": [
                {
                  "copy": "Devon Meadows",
                  "art": "Arley Cornell",
                  "build": "Abhay Singh, Jhalak Singla"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": herobelowtweaky,
              "link": "https://safer.io/",
            }
          ],
          "smallpart": [
            {
              "firstrow":
              {
                "heading": "",
                "text": "lorem ipsum text here if you want some additional things to say.",
                "image": tweakyright1
              },
              "secondrow":
              {
                "heading": "",
                "text": "",
                "image": tweakyleft1
              }
            }
          ],
          "appscreen": [
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery"],
              "backend": ["Jekyll", "Chargedesk Payments", "Hubspot CRM API", "Trello Boards API", "Intercom Chat API", "Drift Chat API"]
            }
          ],
          "nextproj": [
            {
              "title": "Thoughtful MD",
              "text": "Helping to bring stigma-free adult ADHD healthcare for the masses. A whole host of integrations powers NorcalADHD to get patients what they need.",
              "nextprojimage": norcaladhdnext,
              "nextlink": "norcaladhd"
            }
          ]

        }
      ],
      "norachd": [
        {
          "Herosec": [
            {
              "herotitle": "Providing stigma-free adult ADHD care to the masses.",
              "client": "Thoughtful MD",
              "brief": "Thoughtful MD provides doctors with turn-key practice management solutions so that they can worry less about appointments and payments and instead focus on improving the quality of people’s lives. NorcalADHD is the first of many practices setup by Thoughtful MD where ahmrit was tasked to create their digital online presence and an end-to-end practice management system using existing third party integrations. From employee scheduling, recurring payments, HIPAA compliant chat, robust notifications to a global team, and more, ahmrit was able to entirely automate their business workflows.",
              "credits": [
                {
                  "copy": "Dr. Diamond III, MD, MBA",
                  "art": "Devon Meadows",
                  "build": "TBD"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": herobelownoralachd,
              "link": "https://www.norcaladhd.com/",
            }
          ],
          "appscreen": [
            {
              "image": norcalappscreen
            }
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery", "Elementor"],
              "backend": ["Wordpress", "Stripe Payments", "Acuity Scheduling API", "Slack Messaging API", "Drift Chat API", "FreshChat?", "Zapier"]
            }
          ],
          "nextproj": [
            {
              "title": "GlicRx",
              "text": "Creating a product site, API, and white-label solutions to allow Honest Discounts to provide world class technology solutions to their customers.",
              "nextprojimage": glicrxnext,
              "nextlink": "glicrx"
            }
          ]

        }
      ],
      "glicrx": [
        {
          "Herosec": [
            {
              "herotitle": "World class technology solutions can also be white-labeled.",
              "client": "Honest Discounts",
              "brief": "The Honest Discounts web and mobile apps allow customers to search for the lowest price on prescription medications in their locale. It also provides them with easy savings with free discount cards that they can use at all major pharmacies. ahmrit had the responsibility for web and mobile app development as well as creating extendable whitelabel solutions.",
              "credits": [
                {
                  "copy": "Chris Jacobs",
                  "art": "Kelsey Lesko, Stephanie Welter Krause",
                  "build": "Gyan Srivastava, Dilip Kumar, Abhay Singh, Hardeep Singh"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": herobelowglicrx,
              "link": "https://glicrx.com/",
            }
          ],
          "appscreen": [
            {
              "image": glicappscreen
            }
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery", "Angular"],
              "backend": [".net/C#", "MSSQL", "Ionic", "Proprietary APIs"]
            }
          ],
          "nextproj": [
            {
              "title": "Kalvie",
              "text": "Online tutoring for those that need it most. We help Kalvie deliver live virtual classes all around the globe.",
              "nextprojimage": kalvienext,
              "nextlink": "kalvie"
            }
          ]

        }
      ],
      "kalvie": [
        {
          "Herosec": [
            {
              "herotitle": "Creating a system for remote learning with custom virtual classrooms.",
              "client": "Kalvie Inc.",
              "brief": "Kalvie is a learning management system built to provide affordable online tutoring and structured academic courses in developing nations, while providing additional income to teachers. ahmrit developed their platform completely from scratch, leveraging third party integrations where needed. It includes a two-sided marketplace (with parent access), schedule and booking management, global payment gateways, and a virtual classroom with a whiteboard, chat, audio, and video.",
              "credits": [
                {
                  "copy": "David Matta",
                  "art": "Vijay Bisht",
                  "build": "Abhishek Chauhan, Shubham Vishnoi, Jhalak Singla, Gyan Srivastava, Abhay Singh"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": herobelowkalive,
              "link": "https://kalvie.com/",
            }
          ],
          "smallpart": [
            {
              "firstrow":
              {
                "heading": "Lorem ipsum dolor sit amet",
                "text": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an.",
                "image": kalvieright1
              },
              "secondrow":
              {
                "heading": "Lorem ipsum dolor sit amet",
                "text": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an.",
                "image": kalvieleft1
              },
              "thirdrow":
              {
                "heading": "Lorem ipsum dolor sit amet",
                "text": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an.",
                "image": kalvieright2
              }
            }
          ],
          "appscreen": [
          ],
          "kalviescreen": [
            {
              "image": kalviescreen
            }
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery"],
              "backend": [".net/C#", "MSSQL", "SignalR", "WebRTC/Red5/Freeswitch", "MongoDB"]
            }
          ],
          "nextproj": [
            {
              "title": "Glic Health",
              "text": "The Honest Discounts custom reporting dashboard streamlines millions of data points for thousands of users all around the country.",
              "nextprojimage": dashboardnext,
              "nextlink": "dashboard"
            }
          ]

        }
      ],
      "dashboard": [
        {
          "Herosec": [
            {
              "herotitle": "Custom dashboards to streamline millions of data points.",
              "client": "Glic Health",
              "brief": "The Honest Discounts reporting dashboard provides all users at different levels insights into how they are performing within the Honest Discounts discount card program. This allows users to get real-time updates on their performance and see where they can focus and improve. ahmrit was tasked with collecting data from various providers across the countries and building a robust visualization dashboard to serve thousands of users.",
              "credits": [
                {
                  "copy": "",
                  "art": "",
                  "build": "Gyan Srivastava, Dilip Kumar, Abhay Singh, Manoj Kumar"
                }
              ]
            }
          ],
          "herobelow": [
            {
              "herobelowimage": dashboardherobelow,
              "link": "https://reporting.glichealth.com",
            }
          ],
          "smallpart": [
          ],
          "appscreen": [
          ],
          "carousel":[
            {
              "img": dashboardslide,
              "project": "Dashboard — ",
              "description": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an."
            },
            {
              "img": dashboardslide,
              "project": "Dashboard — ",
              "description": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an."
            },
            {
              "img": dashboardslide,
              "project": "Dashboard — ",
              "description": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an."
            },
            {
              "img": dashboardslide,
              "project": "Dashboard — ",
              "description": "Ad mea blandit sententiae, nominavi scriptore. Qui ei wisi antiopam inimicus, eloquentiam interpretaris vis an."
            }
          ],
          "languages": [
            {
              "frontend": ["HTML5", "CSS", "Javascript", "Jquery", "React"],
              "backend": [".net/C#", "MSSQL", "Proprietary Data APIs"]
            }
          ],
          "nextproj": [
            {
              "title": "Safer",
              "text": "Building a product site that helps to amplify Thorn’s mission: help children be safer. Safer protects platforms from abuse content at scale.",
              "nextprojimage": safernext,
              "nextlink": "safer"
            }
          ]

        }
      ],
    }

  ]
  return (
    <div className="Projectpage">
      <Navbar pathname={'/ourwork'} />
      {locationName === 'safer' ?
        <>
          <ProjectHero projecthero={projects[0].safer[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].safer[0].herobelow[0]} />
          <AppsSreens appscreen={projects[0].safer[0].appscreen[0]} />
          <Languages languages={projects[0].safer[0].languages[0]} />
          <NextProject nextproject={projects[0].safer[0].nextproj[0]} />
        </>
        : null}
      {locationName === 'tweaky' ?
        <>
          <ProjectHero projecthero={projects[0].tweaky[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].tweaky[0].herobelow[0]} />
          <Smallpart smallpart={projects[0].tweaky[0].smallpart[0]} />
          <Languages languages={projects[0].tweaky[0].languages[0]} />
          <NextProject nextproject={projects[0].tweaky[0].nextproj[0]} />
        </>
        : null}
      {locationName === 'norcaladhd' ?
        <>
          <ProjectHero projecthero={projects[0].norachd[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].norachd[0].herobelow[0]} />
          <AppsSreens appscreen={projects[0].norachd[0].appscreen[0]} />
          <Languages languages={projects[0].norachd[0].languages[0]} />
          <NextProject nextproject={projects[0].norachd[0].nextproj[0]} />
        </>
        : null}
      {locationName === 'glicrx' ?
        <>
          <ProjectHero projecthero={projects[0].glicrx[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].glicrx[0].herobelow[0]} />
          <AppsSreens appscreen={projects[0].glicrx[0].appscreen[0]} />
          <Languages languages={projects[0].glicrx[0].languages[0]} />
          <NextProject nextproject={projects[0].glicrx[0].nextproj[0]} />
        </>
        : null}
      {locationName === 'kalvie' ?
        <>
          <ProjectHero projecthero={projects[0].kalvie[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].kalvie[0].herobelow[0]} />
          <Smallpart smallpart={projects[0].kalvie[0].smallpart[0]} />
          <Kalviesingle screen={projects[0].kalvie[0].kalviescreen[0]} />
          <Languages languages={projects[0].kalvie[0].languages[0]} />
          <NextProject nextproject={projects[0].kalvie[0].nextproj[0]} />
        </>
        : null}
      {locationName === 'dashboard' ?
        <>
          <ProjectHero projecthero={projects[0].dashboard[0].Herosec[0]} />
          <ProjectsHerobelow herobelow={projects[0].dashboard[0].herobelow[0]} />
          <DashboardSlides carousel={projects[0].dashboard[0].carousel}/>
          <Languages languages={projects[0].dashboard[0].languages[0]} />
          <NextProject nextproject={projects[0].dashboard[0].nextproj[0]} />
        </>
        : null}
      <Footer />
    </div>
  );
}

export default Project;
