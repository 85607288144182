import React from 'react';
// import Typing from 'react-typing-animation';
import aneesh from '../../../assets/images/teams/Aneesh Seth@3x.png';
import abhay from '../../../assets/images/teams/Abhay Singh@3x.png';
import dilip from '../../../assets/images/teams/Dilip Singh@3x.png';
import aayush from '../../../assets/images/teams/Aayush Kamboj@3x.png';
// import asha from '../../../assets/images/teams/Asha Rao@3x.png';
import meher from '../../../assets/images/teams/Meher Darshan @3x.png';
import navdeep from '../../../assets/images/teams/Navdeep Singh@3x.png';
// import preeti from '../../../assets/images/teams/Preeti Chaudhary@3x.png';
// import rekha from '../../../assets/images/teams/Rekha Negi@3x.png';
import shubhamjain from '../../../assets/images/teams/Shubham Jain@3x.png';
import shubhamvisnoi from '../../../assets/images/teams/Shubham Vishnoi@3x.png';
import swati from '../../../assets/images/teams/Swati Bindal@3x.png';


// Styles
import "./team.scss";

function Team(props) {
  debugger;
  const teams = [
    {
      "name": "Aneesh Seth",
      "position": "Team-Lead / QA",
      "identity": "(most known for: their eyebrows)",
      "img":aneesh
    },
    {
      "name": "Abhay Singh",
      "position": "Front-End Lead",
      "identity": "(most known for: their secrecy)",
      "img":abhay
    },
    {
      "name": "Dilip Kr. Singh",
      "position": "Back-End Lead",
      "identity": "(most known for: their ________)",
      "img":dilip
    },
    {
      "name": "Navdeep Singh",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":navdeep
    },
    {
      "name": "Aayush Kamboj",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":aayush
    },
    // {
    //   "name": "Preeti Chaudhary",
    //   "position": "Title / Specialty",
    //   "identity": "(most known for: their ________)",
    //   "img":preeti
    // },
    // {
    //   "name": "Rekha Negi",
    //   "position": "Title / Specialty",
    //   "identity": "(most known for: their ________)",
    //   "img":rekha
    // },
    {
      "name": "Shubham Jain",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":shubhamjain
    },
    {
      "name": "Shubham Vishnoi",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":shubhamvisnoi
    }
    ,
    {
      "name": "Swati Bindal",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":swati
    },
    {
      "name": "Meher Darshan",
      "position": "Title / Specialty",
      "identity": "(most known for: their ________)",
      "img":meher
    }
    // ,
    // {
    //   "name": "Asha Rao",
    //   "position": "Title / Specialty",
    //   "identity": "(most known for: their ________)",
    //   "img":asha
    // }

  ]
  const shuffle=(sourceArray) => {
    for (var i = 0; i < sourceArray.length - 1; i++) {
       var j = i + Math.floor(Math.random() * (sourceArray.length - i));
 
       var temp = sourceArray[j];
       sourceArray[j] = sourceArray[i];
       sourceArray[i] = temp;
    }
    return sourceArray;
 }

  

  return (
    <div className="Team">
      {/* {whatwedoservices.map((data, index) => ( */}
      <>
        <div className="parent">
          <div className="teamsbox">
            {shuffle(teams).map((data, index) => (
              <div key={index + "_b"}>
                <div className="imagebox">
                  <img src={data.img} alt="" />
                </div>
                <div className="contentbox">
                  <div className="name">{data.name}</div>
                  <div className="position">{data.position}</div>
                  <div className="identity">{data.identity}</div>
                </div>
              </div>
            ))}
            <div className="lastindex">
              <div className="contentbox">
                <div className="name"></div>
                <div className="position"></div>
                <div className="identity"></div>
              </div>
            </div>
          </div>

        </div>
      </>
      {/* ))} */}
    </div>
  );
}

export default Team;
