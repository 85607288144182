import { NavLink } from "react-router-dom";

// Styles
import "./Contactwhoweare.scss";


function Contactwhoweare(props) {

  return (
    <div className="Contactwhoweare">
      <div className="titlesec">
        <div className="maintitle">
          <NavLink aria-current="page" to="/contact">Contact Us</NavLink>
          <p>Interested in making something magical together?</p>
        </div>

      </div>
    </div>
  );
}

export default Contactwhoweare;
