import Navbar from '../../components/navbar'
import HomeHero from '../../components/homepage/hero'
import HomeourWork from '../../components/homepage/homeourwork'
import Homewhatwedo from '../../components/homepage/homewhatwedo'
import Footer from '../../components/footer'
// Styles
import "./styles.scss";

function Home() {
  return (
    <div className="homepage">
      <Navbar />
      <HomeHero />
      <HomeourWork />
      <Homewhatwedo/>
      <Footer/>
    </div>
  );
}

export default Home;
