// Styles
import "./languages.scss";


function Languages(props) {
  return (
    <div className="languages">
      <div className="titlesec">

        <div className="maintitle">
          <div className="langtit">Languages</div>
          <div className="lngss">
            <div>
              <div className="titile">
                [front-end]
              </div>
              <div className="langlist">
                <ul>
                {props.languages.frontend.map(item => (  
                  <li>{item}</li>
                  ))}  
                </ul>
              </div>
            </div>
            <div>
              <div className="titile">
              [back-end]
              </div>
              <div className="langlist">
                <ul>
                {props.languages.backend.map(item => (  
                  <li>{item}</li>
                  ))}  
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export default Languages;
