import React from 'react';
import Select, { components } from 'react-select'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Styles
import "./contactHero.scss";
const schema = yup.object().shape({
  status: yup
    .object()
    .shape({
      label: yup.string().required("Please select services"),
      value: yup.string().required("status is required")
    })
    .nullable() // for handling null value when clearing options via clicking "x"
    .required("status is required (from outter null check)"),
  name: yup.string().required("Cqan't blank"),
  email: yup.string().required("Can't blank").email('Invalid'),
  tellus: yup.string().required("Can't blank")
});

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <span></span>
    </components.DropdownIndicator>
  );
};

function ContactHero(props) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = (data) => console.log(data);
  const options = [
    {
      value: "App Development",
      label: "App Development"
    },
    {
      value: "Automating business workflows",
      label: "Automating business workflows"
    },
    {
      value: "Third-party integrations",
      label: "Third-party integrations"
    },
    {
      value: "Data intelligence visualizations, & analytics",
      label: "Data intelligence visualizations, & analytics"
    },
    {
      value: "Digital online presence",
      label: "Digital online presence"
    },
    {
      value: "other",
      label: "other"
    }
  ]
  return (
    <div className="contactHero">
      <div className="parent">
        <h1>Ready for a bit <br />of alchemy?</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input {...register('name', { required: true })} placeholder="Name" /> {/* register an input */}
          {errors.name && <p>{errors.name.message}</p>}
          <input
            id="email"
            {...register("email", {
              required: "Field Can't be blank",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            })}
            placeholder="Email"
            type="email"
          />
          {errors.email && <span role="alert">{errors.email.message}</span>}
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Select
                // defaultValue={options[0]}
                {...field}
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                options={options}
                placeholder="Services"
                components={{ DropdownIndicator }}
              />
            )}
          />
          <p>{errors.status?.message || errors.status?.label.message}</p>
          <textarea
            id="tellus"
            {...register("tellus", {
              required: "Field Can't be blank"
            })}
            placeholder="Tell us a little bit about your project. What are you struggling with? What’s your timeline look like? Dare we ask what your *b* [budget] word is?"
          />
          <p>{errors.tellus?.message}</p>

          <input type="submit" />
        </form>
        <div className="getintouchbox">
          <div className="title">Get in touch</div>
          <p>tech support: <a href="mailto:somethingisbroken@ahmrit.it">somethingisbroken@ahmrit.it</a></p>
          <p>general info: <a href="mailto:holla@ahmr.it">holla@ahmr.it</a></p>
          <p>new business: <a href="mailto:collaborate@ahmrit.it">collaborate@ahmrit.it</a></p>
        </div>
      </div>
    </div>
  );
}

export default ContactHero;
