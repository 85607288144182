// import { NavLink } from "react-router-dom";
// Styles
import "./smallpart.scss";


function Smallpart(props) {
  const pathname = window.location.pathname.split('/')[2]
  return (
    <div className="smallpart">
      <div className="titlesec">

        <div className="maintitle">
          <div className="leftrightparent">
            <div className={pathname==="kalvie" ? "content margintop": "content"}>
              <div className="textsec">
                <p className="boldtxt">{props.smallpart.firstrow.heading}</p>
                <p>{props.smallpart.firstrow.text}</p>
              </div>
              <div className="imagearea">
                <img src={props.smallpart.firstrow.image} alt="" />
              </div>
            </div>
            <div className="content">
              <div className="imagearea leftmar0">
                <img src={props.smallpart.secondrow.image} alt="" />
              </div>
              <div className="textsec secrow">
                <p className="boldtxt">{props.smallpart.firstrow.heading}</p>
                <p>{props.smallpart.secondrow.text}</p>
              </div>

            </div>
            {props.smallpart.thirdrow ? 
            <div className="content">
              <div className="textsec">
                <p className="boldtxt">{props.smallpart.thirdrow.heading}</p>
                <p>{props.smallpart.thirdrow.text}</p>
              </div>
              <div className="imagearea">
                <img src={props.smallpart.thirdrow.image} alt="" />
              </div>
            </div>
            :null }
          </div>
        </div>
      </div>

    </div>
  );
}

export default Smallpart;
