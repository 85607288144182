import React from 'react';
// import Typing from 'react-typing-animation';


// Styles
import "./technologieswhatwedo.scss";

function Technologieswhatwedo(props) {
  const whatwedoservices = [
    {
      "title": "Platforms & E-Commerce",
      "technologies": "Wordpress / Shopify /Woo Commerce / Ghost /Jekyll"
    },
    {
      "title": "Data",
      "technologies": "SQL Server /MySQL / SQLite / Firebase Cloud Storage"
    },
    {
      "title": "Hosting",
      "technologies": "Amazon Web Services / Google Cloud Platform / Firebase"
    },
    {
      "title": "Languages",
      "technologies": "HTML / CSS/JS / SASS/LESS / Javascrapt frameworks"
    }
  ]

  return (
    <div className="Technologieswhatwedo">
      {/* <Typing speed={30} loop> */}
        {whatwedoservices.map((data, index) => (
          <>
            <div className={`parent `+`scroll`+index} key={data.title}>
              <div className="title" data-title={data.title}>
              </div>
              <div>{data.technologies}</div>
            </div>
          </>
        ))}
      {/* </Typing> */}

    </div >
  );
}

export default Technologieswhatwedo;
