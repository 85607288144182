import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import ContactHero from '../../components/contact/hero'
import Clock from '../../components/whoweare/clock'
// Styles
import "./contact.scss";

function ContactPage() {
  return (
    <div className="ContactPage">
      <Navbar />
      <ContactHero/>
      <div className="clockbox contactclock">
        <Clock />
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
