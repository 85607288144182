import { NavLink } from "react-router-dom";
// Styles
import "./nextproject.scss";


function NextProject(props) {
  return (
    <div className="nextproject">
      <div className="titlesec">

        <div className="maintitle">
          <NavLink activeClassName='langtit' aria-current="page" to="/ourwork">Back to Projects</NavLink>
          <div className="lngss">
            <div className="heading"><NavLink aria-current="page" to={`/ourwork/` + props.nextproject.nextlink}>Next Project</NavLink></div>
            <div className="content">
              <div className="textsec">
                <div className="boldtxt">
                  {props.nextproject.title}
                </div>
                {props.nextproject.text}
              </div>
              <div className="imagearea">
                <NavLink aria-current="page" to={`/ourwork/` + props.nextproject.nextlink}>
                  <img src={props.nextproject.nextprojimage} alt="" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default NextProject;
