import { NavLink } from "react-router-dom";
import Homework from '../../../assets/images/homeviewworkimg.png'


// Styles
import "./HomeourWork.scss";


function HomeourWork(props) {
  return (
    <div className="HomeourWork">
      <div className="titlesec">
        <div className="maintitle">
          <NavLink aria-current="page" to="/ourwork">View Our Work</NavLink>
          <p>We bring the flow to your workflow. The mobility to your mobile app. </p>
        </div>
        <div className="subtitle">
          <p>We’ve been called tech alchemists, and we accept.</p>
        </div>
      </div>
      <div className="imagearea">
        <img src={Homework} alt="" />
      </div>
    </div>
  );
}

export default HomeourWork;
